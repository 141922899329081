@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.blur {
  filter: blur(4px);
}

.ean-address {
  border-left: $vl-action-color 1px solid;
  padding-left: $vl-spacing-small;
  margin-left: $vl-spacing-xxsmall;
}

.addressLoading-alert {
  margin-top: 1rem;
}
