@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.wrp-category-tiles {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 3rem;
  @media (max-width: $vl-bp-small) {
    grid-template-columns: 1fr;
  }
  @media (min-width: $vl-bp-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .vl-checkbox-tile:first-of-type,
  .vl-checkbox-tile + .vl-checkbox-tile,
  .vl-checkbox-tile__input:disabled + .vl-checkbox-tile__label {
    margin-top: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto;
  }

  .vl-checkbox-tile__input:not(:disabled) + .vl-checkbox-tile__label {
    height: 100%;
  }

  .vl-checkbox-tile__input {
    height: 100%;
  }

  .vl-checkbox-tile__icon::before {
    font-size: 4.5rem;
  }

  .vl-checkbox-tile__input[type="checkbox"] + .vl-checkbox-tile__label::after,
  .vl-checkbox-tile__input[type="checkbox"]:hover
    + .vl-checkbox-tile__label::after,
  .vl-checkbox-tile__input[type="checkbox"]:checked
    + .vl-checkbox-tile__label::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .vl-checkbox-tile__label {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .vl-checkbox-tile__title:not(&.has-info) {
    margin-bottom: 0;
  }
}
