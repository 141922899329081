@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/src/theme/includes.scss";@import "/home/ec2-user/workspace/Projects/Wonen-Vlaanderen/Mijn VerbouwPremie/create-mijnverbouwpremie-release/frontoffice/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.wrp-section-wizard-step {
  &.vl-step--success > .vl-step__container > .vl-step__wrapper,
  &.vl-step--error > .vl-step__container > .vl-step__wrapper {
    background-color: transparent;
    border-color: transparent;
    padding-top: 0.5rem;
  }

  .wrp-section-wizard-step-button {
    margin-top: 1.5rem;
  }
  .wrp-section-wizard-step-edit-button {
    padding: 0;
  }
}

.wrp-section-wizard-where-am-i {
  .wrp-section-wizard-where-am-i-section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: $vl-bp-small) {
      flex-direction: column-reverse;

      button {
        margin-bottom: 1rem;
      }
    }

    &.steps-overview {
      align-items: baseline;
    }

    .wrp-section-wizard-where-am-i-section-header-buttons {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: flex-start;
    }
  }

  h1.wrp-section-wizard-where-am-i-title,
  h2.wrp-section-wizard-where-am-i-title {
    margin-top: 0;
  }
}

.wrp-section-wizard-pane {
  h2 {
    display: none;
  }
}

#editor-selectieCategorieen-extraInfoParentdossiers,
#editor-selectieCategorieen-extraInfoChilddossiers {
  max-width: 100%;
}
